@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Qalisha';
    src: local('SVN-Qalisha Signature Script'),
        url('./fonts/SVN-Qalisha-Signature-Script.otf') format('woff');
}

@font-face {
    font-family: 'Grotesk';
    src: local('SpaceGrotesk-Regular'),
        url('./fonts/SpaceGrotesk-Regular.ttf') format('woff');
}

.button-name {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    -webkit-box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
        rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
        rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #36395a;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: 'Grotesk', serif;
    height: 48px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    -webkit-transition: -webkit-box-shadow 0.15s, -webkit-transform 0.15s;
    transition: -webkit-box-shadow 0.15s, -webkit-transform 0.15s;
    transition: box-shadow 0.15s, transform 0.15s;
    transition: box-shadow 0.15s, transform 0.15s, -webkit-box-shadow 0.15s,
        -webkit-transform 0.15s;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-name:focus {
    -webkit-box-shadow: #d6d6e7 0 0 0 1.5px inset,
        rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        #d6d6e7 0 -3px 0 inset;
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-name:hover {
    -webkit-box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px,
        rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px,
        rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
}

.button-name:active {
    -webkit-box-shadow: #d6d6e7 0 3px 7px inset;
    box-shadow: #d6d6e7 0 3px 7px inset;
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body,
#root {
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}
